<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-warehouse"
          title="Almacenes"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :headers="headers"
            :items="warehouses"
            :loading="fetchingWarehouses"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <warehouses-table-top />
            </template>
            <template v-slot:[`item.actions`]="{ item: warehouse }">
              <warehouses-actions
                :warehouse="warehouse"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import WarehousesTableTop from './WarehousesTableTop'
  import WarehousesActions from './WarehousesActions.vue'

  export default {
    name: 'WarehousesTable',
    components: {
      WarehousesTableTop,
      WarehousesActions,
    },
    data () {
      return {
        headers: [
          {
            text: 'Código',
            value: 'id',
            sortable: false,
          },
          {
            text: 'Correlativo',
            value: 'code',
            sortable: false,
          },
          {
            text: 'Nombre',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Tipo de almacen',
            value: 'warehouseType',
            align: 'center',
          },
          {
            text: 'Descripción',
            value: 'description',
            align: 'center',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'warehouses',
        'fetchingWarehouses',
      ]),
    },
  }
</script>
