<template>
  <v-form
    v-model="isValidData"
    @submit="submit"
  >
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="data.warehouseTypeId"
              :items="getWarehousesTypes"
              item-text="name"
              item-value="id"
              label="Tipo de almacen"
              no-data-text="No hay tipos disponibles"
              outlined
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="data.code"
              type="text"
              :rules="requiredFieldRules('Correlativo es requerido')"
              outlined
              label="Correlativo"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="data.name"
              type="text"
              :rules="requiredFieldRules('Nombre es requerido')"
              outlined
              label="Nombre"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="data.description"
              outlined
              name="description"
              label="Descripción"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red"
        text
        type="button"
        :disabled="fetchingWarehouses"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        type="submit"
        color="success"
        text
        :loading="fetchingWarehouses"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'AddWarehousesTypesForm',
    data () {
      return {
        isValidData: false,
        data: {
          code: '',
          name: '',
          warehouseTypeId: null,
          description: '',
        },
      }
    },
    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingWarehouses',
        'warehousesTypes',
      ]),
      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      getWarehousesTypes () {
        return this.warehousesTypes.filter(item => item.status !== 0)
      },
    },

    methods: {
      ...mapActions([
        'getWarehousesByParentId',
        'createWarehouses',
      ]),
      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },
      resetData () {
        for (const [key] of Object.entries(this.data)) {
          this.data[key] = '' // Making all keys of the object ''
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.resetData()
        await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async submit (e) {
        e.preventDefault()
        if (this.isValidData && this.businessId !== null) {
          await this.createWarehouses({
            businessId: this.businessId,
            ...this.data,
          })
          this.afterCreate()
        }
      },
    },
  }
</script>
