<template>
  <v-form
    v-model="isValidForm"
    @submit="handleSave"
  >
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="data.warehouseTypeId"
            :items="getWarehousesTypes"
            item-text="name"
            item-value="id"
            label="Tipo de almacen"
            no-data-text="No hay tipos disponibles"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="data.code"
            type="text"
            :rules="requiredFieldRules('Correlativo es requerido')"
            outlined
            label="Correlativo"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="data.name"
            type="text"
            :rules="requiredFieldRules('Nombre es requerido')"
            outlined
            label="Nombre"
            required
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="data.description"
            outlined
            name="description"
            label="Descripción"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        color="red"
        type="button"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        text
        color="green"
        type="submit"
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'EditWarehousesForm',
    props: {
      warehouse: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        isValidForm: false,
        name: '',
        data: {
          code: '',
          name: '',
          warehouseTypeId: null,
          description: '',
        },
      }
    },

    computed: {
      ...mapState(['warehousesTypes', 'user', 'actionsParentBusiness']),
      getWarehousesTypes () {
        return this.warehousesTypes.filter(item => item.status !== 0)
      },
    },

    watch: {
      data (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // // console.log('DATA', v)
      },
    },

    mounted () {
      this.name = this.warehouse.name
      // // // // // // // // // // // // // // // // // // // // // // // // // console.log('ware', this.warehouse)
      this.data = this.warehouse
    },

    methods: {
      ...mapActions([
        'updateWarehouse',
        'getWarehousesByParentId',
      ]),
      async handleSave (e) {
        e.preventDefault()
        if (this.isValidForm) {
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('datita', this.data)
          await this.updateWarehouse({
            id: this.data.warehouseId,
            name: this.data.name,
            code: this.data.code,
            warehouseTypeId: String(this.data.warehouseTypeId),
            description: this.data.description,
          })
          this.$emit('close')
          await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
        }
      },
      requiredFieldRules (errorMessage) {
        return [
          v => !!v || errorMessage,
        ]
      },
    },
  }
</script>
