<template>
  <warehouses-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  // import WarehousesTable from '../components/admin-warehouses/WarehousesTable'
  import WarehousesTable from '../components/admin-warehouses/WarehousesTable.vue'
  export default {
    name: 'AdminWarehouses',
    components: {
      WarehousesTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },

    async created () {
      await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchWarehousesTypes(this.user.businessId || this.actionsParentBusiness)
    },

    methods: {
      ...mapActions([
        'getWarehousesByParentId',
        'fetchWarehousesTypes',
      ]),
    },
  }
</script>
