<template>
  <div>
    <v-tooltip
      v-if="warehouse.status === 1"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouses"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onDeactiveWarehouse"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para inhabilitar el almacen</span>
    </v-tooltip>

    <v-tooltip
      v-else
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouses"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onActiveWarehouse"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para habilitar el almacen</span>
    </v-tooltip>

    <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
      @click="openedEditDialog = true"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-warehouses-dialog
      v-model="openedEditDialog"
      :warehouse="warehouse"
    />
  </div>
</template>

<script>
  import EditWarehousesDialog from './EditWarehousesDialog.vue'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'WarehousesActions',
    components: {
      EditWarehousesDialog,
    },
    props: {
      warehouse: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingWarehouses',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'deleteWarehouse',
        'activeWarehouse',
        'fetchWarehousesTypes',
        'getWarehousesByParentId',
      ]),
      async onDeactiveWarehouse () {
        await this.deleteWarehouse({ id: this.warehouse.warehouseId })
        await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async onActiveWarehouse () {
        await this.activeWarehouse({ id: this.warehouse.warehouseId })
        await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
